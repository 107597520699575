import { Layout } from "antd";
import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/header/header";
import NavBar from "../components/nav-bar/nav-bar";
import {
  EventsModule,
  HiringUshersModule,
  LeadersModule,
  ProfileModule,
  UsherModule,
  TransactionsModule,
  AdminModule,
  CategoriesModule,
  UsherTypesModule,
} from "./moduleRoutes";
import { RotatingLines } from "react-loader-spinner";
import { Innstance } from "../Axios";
import Dashboard from "./dashboard/dashboard";

const Modules: React.FC = () => {
  const { Sider } = Layout;
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    Innstance.interceptors.request.use(
      (config) => {
        setLoading(true);
        return config;
      },
      (error) => {
        setLoading(false);
      }
    );

    Innstance.interceptors.response.use(
      (config) => {
        setLoading(false);
        return config;
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className="content">
      <Layout className="layout">
        <Sider
          width={"280"}
          breakpoint="lg"
          trigger={null}
          collapsible
          theme={"light"}
        >
          <NavBar />
        </Sider>
        <Layout>
          <Header />

          {loading && (
            <div className="loader">
              <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          )}

          <Suspense>
            <Routes>
              <Route path="/*" element={<Dashboard />} />
              <Route path="/events/*" element={<EventsModule />} />
              <Route path="/ushers/*" element={<UsherModule />} />
              <Route path="/hiringUshers/*" element={<HiringUshersModule />} />
              <Route path="/profile/*" element={<ProfileModule />} />
              <Route path="/leaders/*" element={<LeadersModule />} />
              <Route path="/transactions/*" element={<TransactionsModule />} />
              <Route path="/categories/*" element={<CategoriesModule />} />
              <Route path="/types/*" element={<UsherTypesModule />} />
              <Route path="/admin/*" element={<AdminModule />} />
            </Routes>
          </Suspense>
        </Layout>
      </Layout>
    </div>
  );
};

export default Modules;
