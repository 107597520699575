import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./login/login";
import SignUp from "./sign-up/sign-up";
import UpdatePasswordFirstTime from "./update-password-first-time/updatePasswordFirstTime";

const Authentication: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<SignUp />} />
      </Routes>
    </div>
  );
};

export default Authentication;
