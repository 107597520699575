import {
  CATEGORY,
  EVENT,
  META,
  STEP_FOUR,
  STEP_ONE,
  STEP_THREE,
  STEP_TWO,
} from "../../models/events.model";

import {
  EVENT_LIST,
  CATEGORY_LIST,
  GET_RECORD,
  OPEN_MODAL,
  OPEN_DELETE_MODAL,
  STEP_ONE_ACTION,
  STEP_TWO_ACTION,
  STEP_THREE_ACTION,
  STEP_FOUR_ACTION,
} from "./action";

const initialState: {
  EventData: EVENT[];
  CategoryData: CATEGORY[];
  meta: META;
  EventRecord: EVENT;
  openModal: boolean;
  opendeletemodal: boolean;
  deletedRecord: EVENT;
  stepOne: STEP_ONE | null;
  stepTwo: STEP_TWO | null;
  stepThree: STEP_THREE | null;
  stepFour: STEP_FOUR | null;
} = {
  EventData: [],
  meta: {},
  CategoryData: [],
  EventRecord: {},
  openModal: false,
  opendeletemodal: false,
  deletedRecord: {},
  stepOne: null,
  stepTwo: null,
  stepThree: null,
  stepFour: null,
};

export default function EventReducer(
  state = initialState,
  action: {
    type: string;
    payload: {
      data?: EVENT[];
      meta?: META;
      CategoryData?: CATEGORY[];
      EventRecord: EVENT;
      openModal: boolean;
      opendeletemodal: boolean;
      deletedRecord: EVENT;
      stepOne?: STEP_ONE | null;
      stepTwo?: STEP_TWO | null;
      stepThree?: STEP_THREE | null;
      stepFour?: STEP_FOUR | null;
    };
  }
): {
  EventData: EVENT[];
  CategoryData: CATEGORY[];
  meta: META;
  EventRecord: EVENT;
  openModal: boolean;
  opendeletemodal: boolean;
  deletedRecord: EVENT;
  stepOne: STEP_ONE | null;
  stepTwo: STEP_TWO | null;
  stepThree: STEP_THREE | null;
  stepFour: STEP_FOUR | null;
} {
  switch (action.type) {
    case EVENT_LIST:
      return {
        ...state,
        EventData: action.payload.data!,
        meta: action.payload.meta!,
      };
    case CATEGORY_LIST:
      return {
        ...state,
        CategoryData: action.payload.CategoryData!,
      };
    case GET_RECORD:
      return {
        ...state,
        EventRecord: action.payload.EventRecord,
        openModal: true,
      };
    case OPEN_MODAL:
      return {
        ...state,
        openModal: action.payload.openModal,
      };
    case OPEN_DELETE_MODAL:
      return {
        ...state,
        opendeletemodal: action.payload.opendeletemodal,
        deletedRecord: action.payload.deletedRecord,
      };
    case STEP_ONE_ACTION:
      return {
        ...state,
        stepOne: action.payload?.stepOne!,
      };

    case STEP_TWO_ACTION:
      return {
        ...state,
        stepTwo: action.payload.stepTwo!,
      };

    case STEP_THREE_ACTION:
      return {
        ...state,
        stepThree: action.payload.stepThree!,
      };

    case STEP_FOUR_ACTION:
      return {
        ...state,
        stepFour: action.payload.stepFour!,
      };

    default:
      return state;
  }
}
