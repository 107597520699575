import { USER } from "../../models/user.model";
import { SET_USER_DATA } from "./action";

const initialState: { user: USER | null } = {
  user: null,
};

export default function userReducer(
  state = initialState,
  action: { type: string; payload: USER }
): { user: USER | null } {
  switch (action.type) {
    case SET_USER_DATA:
      console.log(action.payload,"payload")
      return {
        ...state,
        user: action?.payload!,
      };
    default:
      return state;
  }
}
