import { Button, Col, Form, Input, Row, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { LOGIN } from "../../models/authentication.model";
import { Innstance } from "../../Axios";
import { useDispatch } from "react-redux";
import { getUser } from "../../store/User/action";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const onSubmit = (values: LOGIN) => {
    setLoading(true);

    Innstance.post<any>("admin/auth/login", values)
      .then((res) => {
        localStorage.setItem(
          "token",
          res.data.data.token_type + " " + res.data.data.access_token
        );
        dispatch(getUser(res.data.data.user));
        message.success("Login Success !");
        setLoading(false);
        navigate("/");
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSubmitFaild = () => {};

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <div className="loginLogo"></div>
      </Col>
      <Col span={12}>
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={onSubmit}
          onFinishFailed={onSubmitFaild}
          className="auth-form"
        >
          <h1>Get Started With USHERS</h1>
          <Form.Item<LOGIN>
            name="email"
            label="Email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input type="email" size="large" />
          </Form.Item>
          <Form.Item<LOGIN>
            name="password"
            label="Password"
            rules={[{ required: true }]}
          >
            <Input.Password
              placeholder="input password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              size="large"
            />
          </Form.Item>
          <Button
            className={`mt-1 login-btn ${loading ? "spinner" : ""}`}
            disabled={loading}
            type="primary"
            size="large"
            htmlType="submit"
          >
            Sign In
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default Login;
