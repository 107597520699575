import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Modules from "../../modules";
import { RootState } from "../../store";

const PrivateRoutes: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.user);

  if (!user && !localStorage.getItem("token")) {
    return <Navigate to={"/login"} />;
  }

  return <Modules />;
};

export default PrivateRoutes;
