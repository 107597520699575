import { lazy } from "react";

export const EventsModule = lazy(() => import("./events/index"));
export const DashboardModule = lazy(() => import("./dashboard/dashboard"));
export const UsherModule = lazy(() => import("./ushers/index"));
export const HiringUshersModule = lazy(() => import("./hiring-ushers/index"));
export const ProfileModule = lazy(() => import("./profile/profile"));
export const LeadersModule = lazy(() => import("./leaders/index"));
export const TransactionsModule = lazy(() => import("./transactions/index"));
export const CategoriesModule = lazy(() => import("./categories/index"));
export const UsherTypesModule = lazy(() => import("./UsherTypes/index"));
export const AdminModule = lazy(() => import("./admin/index"));
export const Privacy = lazy(() => import("./contactUs/contactUs"));
