import { ConfigProvider } from "antd";
import React from "react";
import "./App.scss";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { useStore } from "./store";

import "./App.scss";
import { Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./components/protected-routes/protectedRoutes";
import PublicRoutes from "./components/public-routes/publicRoutes";
import UpdatePasswordFirstTime from "./authentication/update-password-first-time/updatePasswordFirstTime";
import Privacy from "./modules/contactUs/contactUs";

const App: React.FC = () => {
  const { store, persistor } = useStore();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#4c6e5c",
          boxShadow: "none",
        },
      }}
    >
      <div className="App">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Routes>
              <Route path="*" element={<ProtectedRoutes />} />
              <Route path="/login" element={<PublicRoutes />} />
              <Route
                path="/update-password-for-first-time/:token"
                element={<UpdatePasswordFirstTime />}
              />
              <Route path="/privacy/*" element={<Privacy />} />
            </Routes>
          </PersistGate>
        </Provider>
      </div>
    </ConfigProvider>
  );
};

export default App;
