/**
 * Page Title Bar Component
 * Used To Display Page Title & Breadcrumbs
 */
import React from "react";
// import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from "react-router-dom";

import { Breadcrumb } from "antd";

// intl messages

// get display string
const getDisplayString = (sub: any, end: any, lastElement = undefined) => {
  const arr = sub.split("/");
  if (arr.length > 1) {
    return (
      arr[0].charAt(0) + arr[0].slice(1) + arr[1].charAt(0) + arr[1].slice(1)
    );
  } else {
    if (!end) return sub.charAt(0) + sub.slice(1);
    else return sub.charAt(0) + sub.slice(1);
  }
};

// get url string
const getUrlString = (path: any, sub: any, index: number) => {
  if (index === 0) {
    return "/" + path.split(sub)[0] + sub;
  } else {
    return "/" + path.split(sub)[0] + sub;
  }
};

const PageTitleBar: React.FC<{ match: any; lastElement?: any }> = ({
  match,
  lastElement,
}) => {
  const path = match?.substr(1);
  const first = path.includes("?")
    ? path?.substring(0, path.indexOf("?"))
    : path;
  const subPath = path.includes("/") ? first?.split("/") : [`${path}`];
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        {subPath?.length === 1 && subPath[0] === "" ? (
          <p style={{ fontSize: "20px", fontWeight: "500" }}>Dashboard</p>
        ) : (
          <Breadcrumb
            separator="/"
            items={subPath?.slice(0, 2).map((sub: any, index: number) => ({
              title:
                subPath?.slice(0, 2).length == index + 1 ? (
                  sub
                ) : (
                  <Link
                    to={getUrlString(path, sub, index)}
                    style={{ color: "#4c6e5c" }}
                  >
                    {sub}
                  </Link>
                ),
            }))}
          />
        )}
      </div>
    </>
  );
};

// default props value

export default PageTitleBar;
