import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Innstance } from "../../Axios";
import "./dashboard.scss";

const Dashboard = () => {
  const [dashboardState, setDashboardState] = useState<any>();
  useEffect(() => {
    Innstance.get("/admin")
      .then((response) => {
        console.log(response);
        setDashboardState(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div className="dashboard-content mt-4">
      <h1>ِEvent Summary</h1>
      <Row gutter={[20, 20]}>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/events.png")}
              alt=""
              title=""
            />

            <div>
              <span>Total Events</span>
              <span>{dashboardState?.events?.total}</span>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/events.png")}
              alt=""
              title=""
            />
            <div>
              <span>Completed</span>
              <span>{dashboardState?.events?.completed}</span>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/events.png")}
              alt=""
              title=""
            />
            <div>
              <span>Draft</span>
              <span>{dashboardState?.events?.draft}</span>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/events.png")}
              alt=""
              title=""
            />
            <div>
              <span>In Progress</span>
              <span>{dashboardState?.events?.in_progress}</span>
            </div>
          </div>
        </Col>

        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/events.png")}
              alt=""
              title=""
            />
            <div>
              <span>Published</span>
              <span>{dashboardState?.events?.published}</span>
            </div>
          </div>
        </Col>

        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/events.png")}
              alt=""
              title=""
            />
            <div>
              <span>Canceled</span>
              <span>{dashboardState?.events?.canceled}</span>
            </div>
          </div>
        </Col>
      </Row>

      <h1 className="mt-2">Ushers Summary</h1>
      <Row gutter={[20, 20]}>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/usher.png")}
              alt=""
              title=""
            />

            <div>
              <span>Total Ushers</span>
              <span>{dashboardState?.ushers?.total}</span>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/usher.png")}
              alt=""
              title=""
            />
            <div>
              <span>Approved</span>
              <span>{dashboardState?.ushers?.approved}</span>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/usher.png")}
              alt=""
              title=""
            />
            <div>
              <span>In Interview</span>
              <span>{dashboardState?.ushers?.in_interview}</span>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/usher.png")}
              alt=""
              title=""
            />
            <div>
              <span>Rejected</span>
              <span>{dashboardState?.ushers?.rejected}</span>
            </div>
          </div>
        </Col>

        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/usher.png")}
              alt=""
              title=""
            />
            <div>
              <span>Pending</span>
              <span>{dashboardState?.ushers?.pending}</span>
            </div>
          </div>
        </Col>
      </Row>

      <h1 className="mt-2">Transfer Summary</h1>
      <Row gutter={[20, 20]}>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/transactions.jpg")}
              alt=""
              title=""
            />

            <div>
              <span>Total Transfer</span>
              <span>{dashboardState?.transfers_reports?.total}</span>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/transactions.jpg")}
              alt=""
              title=""
            />
            <div>
              <span>Approved</span>
              <span>{dashboardState?.transfers_reports?.approved}</span>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/transactions.jpg")}
              alt=""
              title=""
            />
            <div>
              <span>Pending Approval</span>
              <span>{dashboardState?.transfers_reports?.pending_approval}</span>
            </div>
          </div>
        </Col>

        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/transactions.jpg")}
              alt=""
              title=""
            />
            <div>
              <span>Transfered</span>
              <span>{dashboardState?.transfers_reports?.transferred}</span>
            </div>
          </div>
        </Col>
      </Row>

      <h1 className="mt-2">Team Leader Summary</h1>
      <Row gutter={[20, 20]}>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/usher.png")}
              alt=""
              title=""
            />

            <div>
              <span>Total </span>
              <span>{dashboardState?.team_leader?.total}</span>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/usher.png")}
              alt=""
              title=""
            />
            <div>
              <span>Approved</span>
              <span>{dashboardState?.team_leader?.approved}</span>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="dashboard-content__total felx-start gap-10">
            <img
              className="img"
              src={require("../../assets/img/usher.png")}
              alt=""
              title=""
            />
            <div>
              <span>Pending</span>
              <span>{dashboardState?.team_leader?.pending}</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
