import { USER } from "../../models/user.model";

export const SET_USER_DATA = "SET_USER_DATA";

export function getUser(payload: USER | null) {
  return {
    type: SET_USER_DATA,
    payload: payload,
  };
}
