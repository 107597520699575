import axios from "axios";
import { message } from "antd";

export const Innstance = axios.create({
  baseURL: "https://api.ushersonline.com/api/",
  headers: {
    Authorization: localStorage.getItem("token"),
  },
});

Innstance.interceptors.response.use(
  (response): any => {
    return response;
  },
  (error) => {
    message.error(error?.response?.data?.message);
    if (error?.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);
