import { USHER } from "../../models/usher.model";
import { USHER_ACTIVITY_LIST, USHER_LIST } from "./action";
import { META } from "../../models/events.model";

const initialStat: {
  ushersList: USHER[];
  meta: META;
  usherActivity:any[];
  ActivityMeta:META
} = {
  ushersList: [],
  meta: {},
  usherActivity:[],
  ActivityMeta:{}

};

export default function UsherReducer(
  state = initialStat,
  action: { type: string; payload: { ushersList: USHER[]; meta: META,ushersActivityList:[], } }
): { ushersList: USHER[]; meta: META;usherActivity:any[];
  ActivityMeta:META } {
  switch (action.type) {
    case USHER_LIST: {
      return {
        ...state,
        ushersList: action.payload.ushersList,
        meta: action.payload.meta,
      };
    }
    case USHER_ACTIVITY_LIST:{
      return{
        ...state,
        usherActivity:action.payload.ushersActivityList,
        ActivityMeta:action.payload.meta
        // usherActivity:action.payload.ushersActivityList
        
      }
    }
    default:
      return state;
  }
}
