import {
  CATEGORY,
  EVENT,
  META,
  STEP_FOUR,
  STEP_ONE,
  STEP_THREE,
  STEP_TWO,
} from "../../models/events.model";

export const EVENT_LIST = "EVENT_LIST";
export const OPEN_MODAL = "OPEN_MODAL";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const GET_RECORD = "GET_RECORD";
export const OPEN_DELETE_MODAL = "OPEN_DELETE_MODAL";
export const STEP_ONE_ACTION = "STEP_ONE";
export const STEP_TWO_ACTION = "STEP_TWO";
export const STEP_THREE_ACTION = "STEP_THREE";
export const STEP_FOUR_ACTION = "STEP_FOUR";

export function EventListAction(payload?: EVENT[], meta?: META) {
  return {
    type: EVENT_LIST,
    payload: {
      data: payload,
      meta,
    },
  };
}

export function CategoryListAction(payload?: CATEGORY[]) {
  return {
    type: CATEGORY_LIST,
    payload: {
      CategoryData: payload,
    },
  };
}
export function getEventRecord(payload?: EVENT) {
  return {
    type: GET_RECORD,
    payload: {
      EventRecord: payload,
    },
  };
}
export function openChangeModal(payload: boolean) {
  return {
    type: OPEN_MODAL,
    payload: {
      openModal: payload,
    },
  };
}

export function StepOneCreate(payload?: STEP_ONE) {
  return {
    type: STEP_ONE_ACTION,
    payload: {
      stepOne: payload,
    },
  };
}

export function StepTwoCreate(payload?: STEP_TWO) {
  return {
    type: STEP_TWO_ACTION,
    payload: {
      stepTwo: payload,
    },
  };
}

export function StepThreeCreate(payload?: STEP_THREE[]) {
  return {
    type: STEP_THREE_ACTION,
    payload: {
      stepThree: payload,
    },
  };
}

export function StepFourCreate(payload: STEP_FOUR[]) {
  return {
    type: STEP_FOUR_ACTION,
    payload: {
      stepFour: payload,
    },
  };
}

export function openDeleteModal(payload: { record?: EVENT; open: boolean }) {
  return {
    type: OPEN_DELETE_MODAL,
    payload: {
      opendeletemodal: payload.open,
      deletedRecord: payload.record,
    },
  };
}
