import React from "react";
import { useNavigate } from "react-router-dom";

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const onLogin = () => {
    navigate("/login");
  };

  return (
    <div>
      <button onClick={onLogin}>Login</button>
    </div>
  );
};

export default SignUp;
