import {Transaction} from "../../models/transaction.model"
import { META } from "../../models/events.model";
export const TRANSACTION_LIST="TRANSACTION_LIST"
export const TransactionListAction=(payload?: Transaction[], meta?: META)=>{
    return {
        type: TRANSACTION_LIST,
        payload: {
          transactionList: payload,
          meta,
        },
      };
}