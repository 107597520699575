import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist"; // defaults to localStorage for web

import rootReducer from "./rootReducer";
import session from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage: session,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

let persistor = persistStore(store);

export type RootState = ReturnType<typeof persistedReducer>;

export function useStore() {
  return { store, persistor };
}
