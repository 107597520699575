import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const Privacy = () => {
  const navigate = useNavigate();
  return (
    <div style={{ padding: "0 40px" }}>
      <p>
        Ushers company built the Proline app as a Free app. This SERVICE is
        provided by Usher company at no cost and is intended for use as is. This
        page is used to inform visitors regarding our policies with the
        collection, use, and disclosure of Personal Information if anyone
        decided to use our Service. If you choose to use our Service, then you
        agree to the collection and use of information in relation to this
        policy. The Personal Information that we collect is used for providing
        and improving the Service. We will not use or share your information
        with anyone except as described in this Privacy Policy. The terms used
        in this Privacy Policy have the same meaning as in our Terms and
        Conditions, which are accessible at Ushers unless otherwise defined in
        this Privacy Policy. Information Collection and Use For a better
        experience, while using our Service, we may require you to provide us
        with certain personally identifiable information, including but not
        limited to name, phone number, photo, bank account. **Children’s
        Privacy** These Services do not address anyone under the age of 18. We
        do not knowingly collect personally identifiable information from
        children under 18 years of age. In the case we discover that a child
        under 18 has provided us with personal information, we immediately
        delete this from our servers. Changes to This **Privacy Policy** We may
        update our Privacy Policy from time to time. Thus, you are advised to
        review this page periodically for any changes. We will notify you of any
        changes by posting the new Privacy Policy on this page. Contact Us If
        you have any questions or suggestions about our Privacy Policy, do not
        hesitate to contact us at
        [Ushrs@addcommun.com](mailto:Ushrs@addcommun.com)
      </p>
      <p>
        سياسة الخصوصية بدون تكلفة وهي مخصصة الإستخدام كما هي تُستخدم Ushers
        كتطبيق مجاني. هذه الخدمة مقدمة من هذه الصفحة إعلام الزائرين بسياساتنا
        بجمع المعلومات الشخصية واستخدامها والكشف عنها إذا قرر أي شخص استخدام
        خدمتنا. إذا اخترت استخدام خدمتنا، فأنت توافق على جمع واستخدام المعلومات
        المتعلقة بهذه السياسة. تُستخدم المعلومات الشخصية التي نجمعها لتوفير
        الخدمة وتحسينها. لن نستخدم أو نشارك معلوماتك مع أي شخص باستثناء ما هو
        موضح في سياسة الخصوصية هذا. المصطلحات المستخدمة في سياسة الخصوصية هذه
        لها نفس المعاني الواردة في الشروط الأحكام ما لم يتم تحديد خالف ذلك في
        سياسة الخصوصية Ushers الخاصة بنا، والتي يمكن الوصول إليها في جمع
        المعلومات واستخدامها . للحصول على تجربة أفضل، أثناء استخدام خدمتنا، قد
        نطلب منك تزويدنا بمعلومات تعريف شخصية المصرفي. سيتم الأحتفاظ بما في ذلك
        على سبيل المثال الاسم ورقم الهاتف والصورة والحساب معينة، .هذه بالمعلومات
        التي نطلبها من قبلنا واستخدامها كما هو موضح في سياسة الخصوصية **حماية**
        نحن نقدر ثقتك في تزويدنا بمعلوماتك الشخصية، وبالتالي فإننا نسعى جاهدين
        الستخدام وسائل مقبولة تجاريًا لحمايتها. لكن تذكر أنه ال توجد طريقة نقل
        عبر اإلنترنت أو طريقة تخزين إلكتروني آمنة والتي يمكننا ضمان أمانها
        المطلق وموثوقة بنسبة ٪100 . **خصوصية الأطفال** تتعامل هذه الخدمات مع أي
        شخص يقل عمره عن 18 عاما. نحن ال نجمع عن قصد معلومات تعريف شخصية من
        األشخاص الذين تقل أعمارهم عن 18 عاما. في حالة اكتشافنا أن شخص أقل من 18
        عاما قد زودنا بمعلومات شخصية، فإننا نقوم بحذفها على الفور من قاعدة
        البيانات. **التغييرات على سياسة الخصوصية** قد نقوم بتحديث سياسة الخصوصية
        الخاصة بنا من وقت آلخر. وبالتالي، يُنصح بمراجعة هذه الصفحة بشكل دوري
        لمعرفة أي تغييرات. سنخبرك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة
        على هذه الصفحة. اتصل بنا إذا كان لديك أي أسئلة أو اقتراحات حول سياسة
        الخصوصية الخاصة بنا، فال تتردد في الاتصال بنا على Ushrs@addcommun.com
      </p>

      <Button type="primary" onClick={() => navigate(-1)}>
        Back
      </Button>
    </div>
  );
};

export default Privacy;
