import { Breadcrumb, Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Innstance } from "../../Axios";
import { RootState } from "../../store";
import { getUser } from "../../store/User/action";
import PageTitleBar from "../PageTitlebar";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const onOpenProfile = () => {
    navigate("/profile");
  };
  const { user } = useSelector((state: RootState) => state.user);

  if (!user) {
    Innstance.get("admin/me")
      .then((response) => {
        dispatch(getUser(response.data.data));
      })
      .catch((error) => console.log(error));
  }

  return (
    <header>
      <Row>
        <Col span={8}>
          <PageTitleBar match={location.pathname} />
        </Col>
        <Col span={6} offset={10}>
          <div className="profile" onClick={onOpenProfile}>
            <div className="profile__user">
              <img
                src={user?.image ?? require("../../assets/img/logo.jpg")}
                alt=""
                title="user"
              />
              <p>{user?.name}</p>
            </div>
          </div>
        </Col>
      </Row>
    </header>
  );
};

export default Header;
