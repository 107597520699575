import {Transaction} from "../../models/transaction.model"

import { META } from "../../models/events.model";
import { TRANSACTION_LIST } from "./actions";

const initialStat: {
  transactionList: Transaction[];
  meta: META;
 
} = {
  transactionList: [],
  meta: {},


};

export default function TransactionReducer(
  state = initialStat,
  action: { type: string; payload: { transactionList: Transaction[]; meta: META } }
): { transactionList: Transaction[]; meta: META;} {
  switch (action.type) {
    case TRANSACTION_LIST: {
      return {
        ...state,
        transactionList: action.payload.transactionList,
        meta: action.payload.meta,
      };
    }
    
    default:
      return state;
  }
}
