import { Button, Col, Form, Input, message, Row } from "antd";
import React, { useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { useNavigate, useParams } from "react-router-dom";
import { Innstance } from "../../Axios";

const UpdatePasswordFirstTime: React.FC = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const onSubmit = (values: { password: string }) => {
    console.log(values);
    Innstance.post(`/admin/auth/update-password-for-first-time/${token}`, {
      ...values,
    })
      .then((response) => {
        console.log(response);
        message.success("Password Changed Successfully !");
        navigate("/login");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col lg={12}>
        <div className="loginLogo"></div>
      </Col>
      <Col lg={12}>
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={onSubmit}
          className="auth-form"
        >
          <h1>Get Started With USHERS</h1>

          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true }]}
          >
            <Input.Password
              placeholder="input password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              size="large"
            />
          </Form.Item>
          <Button
            className={`mt-1 login-btn ${loading ? "spinner" : ""}`}
            disabled={loading}
            type="primary"
            size="large"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default UpdatePasswordFirstTime;
