import { META } from "../../models/events.model";
import { USHER } from "../../models/usher.model";

export const USHER_LIST = "USHER_LIST";
export const USHER_ACTIVITY_LIST="USHER_ACTIVITY_LIST"

export function UsherListAction(payload?: USHER[], meta?: META) {
  return {
    type: USHER_LIST,
    payload: {
      ushersList: payload,
      meta,
    },
  };
}

export function UsherActivityListAction(payload?: USHER[], meta?: META) {
  return {
    type: USHER_ACTIVITY_LIST,
    payload: {
      ushersActivityList: payload,
      meta,
    },
  };
}