import { combineReducers } from "redux";
import EventReducer from "./Event/reducer";
import UsherReducer from "./Ushers/reducer";
import userReducer from "./User/reducer";
import TransactionReducer from "./Transactions/reducer";
const rootReducer = combineReducers({
  events: EventReducer,
  ushers: UsherReducer,
  user: userReducer,
  transactions: TransactionReducer,
});

export default rootReducer;
