import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import Authentication from "../../authentication/authentication";
import { RootState } from "../../store";

const PublicRoutes: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.user);

  if (user) {
    return <Navigate to={"/"} />;
  }

  return <Authentication />;
};

export default PublicRoutes;
